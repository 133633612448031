<template>
  <div class="yimiao">
    <div style="margin:20px 10px;">
      <div class="biankuang1"></div><span class="titleBian">工单信息</span>
      <div>
        <div class="box">
          <van-field v-model="datafrom.Title"
            :border="false"
            required
            label="标 题"
            placeholder="请输入标题" />
          <div class="submit"
            style="padding:10px 7px;">
            <span style="color: red; font-size: 14px; letter-spacing: 8px">*</span>
            <span style="color: #1B2024;font-size: 16px; ">工单类型</span>
            <div style="margin-left: 12%;display: inline-block;">
              <van-radio-group v-model="datafrom.WOType"
                style="line-height:20px;"
                direction="horizontal">
                <van-radio name="1">服务</van-radio>
                <van-radio name="2">培训</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="submit"
            style="padding:10px 7px;">
            <span style="color: red; font-size: 14px; letter-spacing: 8px">*</span>
            <span style="color: #1B2024;font-size: 16px; ">紧急程度</span>
            <div style="margin-left: 12%;display: inline-block;">
              <van-radio-group v-model="datafrom.Urgency"
                style="line-height:20px;"
                direction="horizontal">
                <van-radio name="1">高</van-radio>
                <van-radio name="2">中</van-radio>
                <van-radio name="3">低</van-radio>
              </van-radio-group>
            </div>
          </div>
          <van-field v-model="datafrom.SoName"
            :border="false"
            label="系统"
            @click="SoftId = true"
            readonly="readonly"
            required
            placeholder="请选择系统" />
          <van-popup v-model="SoftId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="SysSoftList"
              value-key="Name"
              @cancel="SoftId = false"
              @confirm="onSoftId">
            </van-picker>
          </van-popup>
          <van-field v-model="datafrom.DealManName"
            :border="false"
            label="负责人"
            @click="DealManId = true"
            readonly="readonly"
            required
            placeholder="请选择负责人" />
          <van-popup v-model="DealManId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="DealManList"
              value-key="Name"
              @cancel="DealManId = false"
              @confirm="onDealManId">
            </van-picker>
          </van-popup>

          <!-- <van-cell-group>
            <van-field v-model="datafrom.StartDate"
              label="开始日期"
              @click="datafromStartDate = true"
              readonly="readonly"
              required
              placeholder="请选择开始日期" />
            <van-popup v-model="datafromStartDate"
              position="bottom"
              get-container="body">
              <van-datetime-picker show-toolbar
                type="date"
                title="请选择开始日期"
                :min-date="minDate"
                :max-date="maxDate"
                @cancel="datafromStartDate = false"
                @confirm="datafromStartDateClick">
              </van-datetime-picker>
            </van-popup>
          </van-cell-group>
          <van-cell-group>
            <van-field v-model="datafrom.EndDate"
              label="结束日期"
              @click="datafromEndDate = true"
              readonly="readonly"
              required
              placeholder="请选择结束日期" />
            <van-popup v-model="datafromEndDate"
              position="bottom"
              get-container="body">
              <van-datetime-picker show-toolbar
                type="date"
                title="请选择结束日期"
                :min-date="minDate"
                :max-date="maxDate"
                @cancel="datafromEndDate = false"
                @confirm="ondatafromEndDate">
              </van-datetime-picker>
            </van-popup>
          </van-cell-group> -->
        </div>
        <div class="biankuang1"></div><span class="titleBian">内容</span>
        <div class="box">
          <van-cell-group>
            <div style="width:100%;margin:auto;border: none;">
              <Editor :value.sync="datafrom.Content" />
            </div>
          </van-cell-group>
        </div>

        <div style="margin:10px;">
          <van-uploader max-count="9"
            accept=".pdf,.docx.doc,.xlsx,txt"
            :after-read="handleAvatarSucces">
            <van-button plain
              type="info">上传附件</van-button>
          </van-uploader>
          <div v-if="Anndex.length != 0"
            style="color: #49a0fa">
            <div v-for="(item ,index) in Anndex"
              :key="index+'aa'">
              {{  item.substring(item.lastIndexOf("/") + 1)}}
              <van-icon name="clear"
                @click="deletAnndex(index)"
                style="font-size: 18px;position: relative;top: 3px;"
                color="#eb2672" />
            </div>
          </div>
        </div>

        <div>
          <van-field v-model="datafrom.Remark"
            type="textarea"
            placeholder="请输入备注" />
        </div>

      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info"
          style="margin:10px 0;"
          @click="postfrom()">保存</van-button>
        <!-- <van-button style="border: 1px solid #e6e6e6;margin:10px 0;"
          type="
          default"
          @click="fanhui()">返回</van-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as dd from 'dingtalk-jsapi';
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import config from "@/config";
import { GetSysSoftPage, GetUserPage, SaveWorkOrder, GetMyWorkOrderPage } from "@/api/aly";
import Compressor from "compressorjs";
import axios from "axios";
import Editor from "./Editor/index"; // 富文本编辑器
import { getwgToken } from "@/utils/auth";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Editor
  },
  data () {
    return {
      // 商品图片
      jkImageList: [],
      NativeCantonCodedata: [],
      idList: {
        organCode: "",
        RId: undefined,
      },
      url: config.apiUploadFiles,
      datafrom: {
        WOId: 0,  //id、
        WOType: "1",
        Urgency: "1",
        Title: "",
        Content: "",
        Anndex: "",
        ProjectId: "",
        SoftId: "",
        DealManId: "",
        Remark: "",
        accToken: "",
      },
      Anndex: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromStartDate: false,
      datafromEndDate: false,
      recumenFend: false, //出生日期
      SysSoftList: [],
      DealManList: [],
      DealManId: false,
      SoftId: false,
    };
  },
  created () {
    // console.log(this.$route.accToken);
    // console.log(getwgToken());
    if (this.$route.query.accToken != undefined && this.$route.query.accToken != null && this.$route.query.accToken != '') {
      this.datafrom.accToken = this.$route.query.accToken
    } else {
      if (getwgToken() != null) {
        this.datafrom.accToken = getwgToken()
      }
    }
    this.getSysSoftPage()
    this.getUserPage()
    if (this.$route.params.woid != 0) {
      this.getMyWorkOrderPage(this.$route.params.woid)
    }
    // this.shopXq()
  },
  methods: {
    // 获取工单
    getMyWorkOrderPage (val) {
      GetMyWorkOrderPage(
        {
          limit: 999,
          accToken: getwgToken()
        }
      ).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            const e = res.data.data[i];
            if (e.WOId == val) {
              this.datafrom = e;
              this.datafrom.WOType = e.WOType + "";
              this.datafrom.Urgency = e.Urgency + "";
              this.datafrom.SoftId = e.SoftId + "";
              this.datafrom.SoName = e.SoftName;
              this.datafrom.DealManId = e.DealManId + "";
              this.datafrom.DealManName = e.DealMan;
              this.datafrom.accToken = getwgToken();
            }
          }
        }
      })
    },
    // 获取系统列表
    getSysSoftPage () {
      GetSysSoftPage({
        limit: 999,
        accToken: this.datafrom.accToken
      }).then((res) => {
        if (res.data.code == 0) {
          this.SysSoftList = res.data.data
        }
      })
    },
    // 负责人
    getUserPage () {
      GetUserPage({
        limit: 999,
        accToken: this.datafrom.accToken
      }).then((res) => {
        if (res.data.code == 0) {
          this.DealManList = res.data.data
        }
      })
    },
    // 上传 相关
    handleAvatarSucces (file) {
      Toast.loading({
        message: '上传中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      let url = this.url;
      let formData = new FormData();
      formData.append('file', file.file);//必须是file.file，不然会报错
      //添加请求头
      let config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      };
      axios
        .post(url, formData, config)
        .then((res) => {
          if (res.data.code == 0) {
            this.Anndex.push(res.data.data)
          }
        })
    },
    // 删除附件
    deletAnndex (val) {
      console.log(val);
      this.Anndex.splice(val, 1);
    },
    // 系统选择器
    onSoftId (val) {
      this.datafrom.SoftId = val.SoftId;
      this.datafrom.SoName = val.Name;
      this.SoftId = false;
    },
    // 用户选择器
    onDealManId (val) {
      this.datafrom.DealManId = val.UserId;
      this.datafrom.DealManName = val.Name;
      this.DealManId = false;
    },

    //上传商品图片
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                // console.log(res.data.data);
                that.datafrom.Poster = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除商品图片
    jkDelete (file) {
      this.jkImageList = []
      this.datafrom.Poster = ""
    },

    // 提交
    postfrom: function () {
      // dd.biz.navigation.goBack({
      //   onSuccess: function (result) {
      //     /*result结构
      //     {}
      //     */
      //   },
      //   onFail: function (err) { }
      // })
      if (this.datafrom.Title == "") {
        Toast.fail("请输入标题");
        return false;
      }
      if (this.datafrom.SoftId == "") {
        Toast.fail("请选择系统");
        return false;
      }
      if (this.datafrom.DealManId == "") {
        Toast.fail("请选择负责人");
        return false;
      }
      // this.datafrom.shopId
      this.datafrom.Anndex = this.Anndex + [];
      // this.datafrom.ShopId = this.$route.params.shopId;
      SaveWorkOrder(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "保存成功!",
          }).then(() => {
            if (this.datafrom.WOId != 0) {
              this.$router.go(-1)
            } else {
              this.$router.push({
                name: "myWorkOrderXq",
                query: {
                  woid: res.data.woid,
                  roles: this.$route.query.roles
                },
              });
              // dd.biz.navigation.goBack({
              //   onSuccess: function (result) {
              //     /*result结构
              //     {}
              //     */
              //   },
              //   onFail: function (err) { }
              // })
            }


          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "保存失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      })
    },
    gitocodelist () {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.push({
        name: "ygList",
        query: { seId: this.$route.query.seId, ekind: this.$route.query.ekind },
      });
    },
    // 获取身份证
    IdNum (value) {
      console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go (val) {
      var iden =
        this.datafrom.IdCard == ""
          ? this.datafrom.IdCard
          : this.datafrom.IdCard;
      // var iden = this.dataForms.IdNumber;
      console.log(iden);
      var id = iden.substring(0, 6);
      // this.datafrom.NativeCantonCode = id;
      // console.log(this.datafrom.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
        this.currentDate = new Date(this.datafrom.Birthday);
        console.log(this.datafrom.Birthday);
      } else {
        sex = 1;
        // this.hzlist.Sex = sex;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
        this.currentDate = new Date(this.datafrom.Birthday);
        console.log(this.datafrom.Birthday);
      }
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onyimiao (val) {
      this.datafrom.yimiao = val.id;
      this.datafrom.yimiaoName = val.Name;
      this.yimiao = false;
    },
    // 结束日期
    ondatafromEndDate (val) {
      this.datafrom.EndDate = this.dateformat(val);;
      this.datafromEndDate = false;
    },
    // 开始日期
    datafromStartDateClick (val) {
      this.datafrom.StartDate = this.dateformat(val);;
      this.datafromStartDate = false;
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.biankuang1 {
  border: 2px solid #397aff;
  width: 0;
  height: 10px;
  float: left;
  border-radius: 2px;
  background: #397aff;
  margin: 6px 0;
}
.titleBian {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10px;
}
.box {
  background-color: white;
  margin: 20px 0px;
  padding: 15px;
  border-radius: 8px;
}

/deep/.van-hairline--top-bottom::after {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}
.imgs {
  margin: 0 15px;
}
.yimiao {
  background-color: #f8f8f8;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
</style>
